
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.additional-documents-item {
  position: relative;
  display: flex;
  align-items: center;
  /*height: 72px;*/
  padding: 16px;
  background: $color-grayscale-05;
  border-radius: 12px;
  text-decoration: none;

  &,
  &__action-button {
    color: $color-black;
    transition: color $base-animation-time $base-animation-function;
  }

  &__icon,
  &__action-button {
    width: 24px;
    flex-shrink: 0;
  }

  &__title {
    padding-left: 8px;
    padding-right: 8px;
    font-size: 14px;
    line-height: 20px;
    flex-grow: 1;
  }

  &__action-button {
    &:not(:last-child) {
      margin-right: 20px;
    }

    &:hover {
      color: $color-client-portal-logo;
    }
  }

  &:hover {
    color: $color-client-portal-logo;
  }

  &:not(:last-of-type) {
    margin-bottom: 16px;
  }
}
